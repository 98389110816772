import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'dat.gui'
import waterVertexShader from './shaders/water/vertex.glsl'
import waterFragmentShader from './shaders/water/fragment.glsl'

import mediumVertexShader from './shaders/medium/vertex.glsl'
import mediumFragmentShader from './shaders/medium/fragment.glsl'

/**
 * Textures
 */
 const textureLoader = new THREE.TextureLoader()
 const particleTexture = textureLoader.load('/textures/particles/2.png')


/**
 * Base
 */
// Debug
const gui = new dat.GUI({ width: 340, closed: true })
const debugObject = {}
const smallRing = gui.addFolder('smallRing')
const medRing = gui.addFolder('medRing')
const bigRing = gui.addFolder('bigRing')

const parameters = {
    materialColor: '#ffeded'
}

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()


/**
 * Sizes
 */
 const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

/**
 * Water
 */
// Geometry
const smallGeometry = new THREE.TorusGeometry( 3.3, 0.2, 16, 100 )
const mediumGeometry = new THREE.TorusGeometry( 4, 0.4, 16, 100 )
const largeGeometry = new THREE.TorusGeometry( 5.2, 0.7, 16, 100 )

// Colors
debugObject.depthColor = '#49c8ef'
debugObject.surfaceColor = '#003952'

debugObject.depthColorM = '#f5d47b'
debugObject.surfaceColorM = '#b5892d'

debugObject.depthColorL = '#cd5928'
debugObject.surfaceColorL = '#9d451f'

smallRing.addColor(debugObject, 'depthColor').onChange(() => { smallMaterial.uniforms.uDepthColor.value.set(debugObject.depthColor) })
smallRing.addColor(debugObject, 'surfaceColor').onChange(() => { smallMaterial.uniforms.uSurfaceColor.value.set(debugObject.surfaceColor) })

medRing.addColor(debugObject, 'depthColorM').onChange(() => { mediumMaterial.uniforms.uDepthColor.value.set(debugObject.depthColorM) })
medRing.addColor(debugObject, 'surfaceColorM').onChange(() => { mediumMaterial.uniforms.uSurfaceColor.value.set(debugObject.surfaceColorM) })

bigRing.addColor(debugObject, 'depthColorL').onChange(() => { largeMaterial.uniforms.uDepthColor.value.set(debugObject.depthColorL) })
bigRing.addColor(debugObject, 'surfaceColorL').onChange(() => { largeMaterial.uniforms.uSurfaceColor.value.set(debugObject.surfaceColorL) })




// Material
const smallMaterial = new THREE.ShaderMaterial({
    vertexShader: waterVertexShader,
    fragmentShader: waterFragmentShader,
    uniforms:
    {
        uTime: { value: 0 },
        
        uBigWavesElevation: { value: 1 },
        uBigWavesFrequency: { value: new THREE.Vector2(0.3, 0.1) },
        uBigWavesSpeed: { value: 0.5 },

        uSmallWavesElevation: { value: 0.375 },
        uSmallWavesFrequency: { value: 9 },
        uSmallWavesSpeed: { value: 0.3 },
        uSmallIterations: { value: 0 },

        uDepthColor: { value: new THREE.Color(debugObject.depthColor) },
        uSurfaceColor: { value: new THREE.Color(debugObject.surfaceColor) },
        uColorOffset: { value: 0.6 },
        uColorMultiplier: { value: 0.4 }
    }
})

// Medium Material
const mediumMaterial = new THREE.ShaderMaterial({
    vertexShader: mediumVertexShader,
    fragmentShader: mediumFragmentShader,
    uniforms:
    {
        uTime: { value: 0 },
        
        uBigWavesElevation: { value: 1 },
        uBigWavesFrequency: { value: new THREE.Vector2(0.3, 0.1) },
        uBigWavesSpeed: { value: 0.5 },

        uSmallWavesElevation: { value: 0.375 },
        uSmallWavesFrequency: { value: 9 },
        uSmallWavesSpeed: { value: 0.3 },
        uSmallIterations: { value: 0 },

        uDepthColor: { value: new THREE.Color(debugObject.depthColorM) },
        uSurfaceColor: { value: new THREE.Color(debugObject.surfaceColorM) },
        uColorOffset: { value: 0.6 },
        uColorMultiplier: { value: 0.4 }
    }
})


const largeMaterial = new THREE.ShaderMaterial({
    vertexShader: waterVertexShader,
    fragmentShader: waterFragmentShader,
    uniforms:
    {
        uTime: { value: 0 },
        
        uBigWavesElevation: { value: 1 },
        uBigWavesFrequency: { value: new THREE.Vector2(0.3, 0.1) },
        uBigWavesSpeed: { value: 0.5 },

        uSmallWavesElevation: { value: 0.375 },
        uSmallWavesFrequency: { value: 9 },
        uSmallWavesSpeed: { value: 0.3 },
        uSmallIterations: { value: 0 },

        uDepthColor: { value: new THREE.Color(debugObject.depthColorL) },
        uSurfaceColor: { value: new THREE.Color(debugObject.surfaceColorL) },
        uColorOffset: { value: 0.6 },
        uColorMultiplier: { value: 0.4 }
    }
})


//Small Ring GUI
smallRing.add(smallMaterial.uniforms.uBigWavesElevation, 'value').min(0).max(1).step(0.001).name('uBigWavesElevation')
smallRing.add(smallMaterial.uniforms.uBigWavesFrequency.value, 'x').min(0).max(10).step(0.001).name('uBigWavesFrequencyX')
smallRing.add(smallMaterial.uniforms.uBigWavesFrequency.value, 'y').min(0).max(10).step(0.001).name('uBigWavesFrequencyY')
smallRing.add(smallMaterial.uniforms.uBigWavesSpeed, 'value').min(0).max(4).step(0.001).name('uBigWavesSpeed')


smallRing.add(smallMaterial.uniforms.uSmallWavesElevation, 'value').min(0).max(1).step(0.001).name('uSmallWavesElevation')
smallRing.add(smallMaterial.uniforms.uSmallWavesFrequency, 'value').min(0).max(30).step(0.001).name('uSmallWavesFrequency')
smallRing.add(smallMaterial.uniforms.uSmallWavesSpeed, 'value').min(0).max(4).step(0.001).name('uSmallWavesSpeed')
smallRing.add(smallMaterial.uniforms.uSmallIterations, 'value').min(0).max(5).step(1).name('uSmallIterations')

smallRing.add(smallMaterial.uniforms.uColorOffset, 'value').min(0).max(1).step(0.001).name('uColorOffset')
smallRing.add(smallMaterial.uniforms.uColorMultiplier, 'value').min(0).max(10).step(0.001).name('uColorMultiplier')


//medRing GUI
medRing.add(mediumMaterial.uniforms.uBigWavesElevation, 'value').min(0).max(1).step(0.001).name('uBigWavesElevation')
medRing.add(mediumMaterial.uniforms.uBigWavesFrequency.value, 'x').min(0).max(10).step(0.001).name('uBigWavesFrequencyX')
medRing.add(mediumMaterial.uniforms.uBigWavesFrequency.value, 'y').min(0).max(10).step(0.001).name('uBigWavesFrequencyY')
medRing.add(mediumMaterial.uniforms.uBigWavesSpeed, 'value').min(0).max(4).step(0.001).name('uBigWavesSpeed')


medRing.add(mediumMaterial.uniforms.uSmallWavesElevation, 'value').min(0).max(1).step(0.001).name('uSmallWavesElevation')
medRing.add(mediumMaterial.uniforms.uSmallWavesFrequency, 'value').min(0).max(30).step(0.001).name('uSmallWavesFrequency')
medRing.add(mediumMaterial.uniforms.uSmallWavesSpeed, 'value').min(0).max(4).step(0.001).name('uSmallWavesSpeed')
medRing.add(mediumMaterial.uniforms.uSmallIterations, 'value').min(0).max(5).step(1).name('uSmallIterations')

medRing.add(mediumMaterial.uniforms.uColorOffset, 'value').min(0).max(1).step(0.001).name('uColorOffset')
medRing.add(mediumMaterial.uniforms.uColorMultiplier, 'value').min(0).max(10).step(0.001).name('uColorMultiplier')


//Large Ring GUI
bigRing.add(largeMaterial.uniforms.uBigWavesElevation, 'value').min(0).max(1).step(0.001).name('uBigWavesElevation')
bigRing.add(largeMaterial.uniforms.uBigWavesFrequency.value, 'x').min(0).max(10).step(0.001).name('uBigWavesFrequencyX')
bigRing.add(largeMaterial.uniforms.uBigWavesFrequency.value, 'y').min(0).max(10).step(0.001).name('uBigWavesFrequencyY')
bigRing.add(largeMaterial.uniforms.uBigWavesSpeed, 'value').min(0).max(4).step(0.001).name('uBigWavesSpeed')


bigRing.add(largeMaterial.uniforms.uSmallWavesElevation, 'value').min(0).max(1).step(0.001).name('uSmallWavesElevation')
bigRing.add(largeMaterial.uniforms.uSmallWavesFrequency, 'value').min(0).max(30).step(0.001).name('uSmallWavesFrequency')
bigRing.add(largeMaterial.uniforms.uSmallWavesSpeed, 'value').min(0).max(4).step(0.001).name('uSmallWavesSpeed')
bigRing.add(largeMaterial.uniforms.uSmallIterations, 'value').min(0).max(5).step(1).name('uSmallIterations')

bigRing.add(largeMaterial.uniforms.uColorOffset, 'value').min(0).max(1).step(0.001).name('uColorOffset')
bigRing.add(largeMaterial.uniforms.uColorMultiplier, 'value').min(0).max(10).step(0.001).name('uColorMultiplier')


// Mesh
const smallring = new THREE.Mesh(smallGeometry, smallMaterial)
//Checks 
if(sizes.width <= 991 ) { smallring.position.x = 0; smallring.position.y = 5 } else { smallring.position.x = 11 }
smallring.rotation.x = 10
scene.add(smallring)

//Medium Ring
const mediumring = new THREE.Mesh(mediumGeometry, mediumMaterial)
if(sizes.width <= 991 ) { mediumring.position.x = 0; mediumring.position.y = 5 } else { mediumring.position.x = 11 }
mediumring.rotation.x = -10
scene.add(mediumring)

//Large Ring
const largering = new THREE.Mesh(largeGeometry, largeMaterial)
if(sizes.width <= 991 ) { largering.position.x = 0; largering.position.y = 5 } else { largering.position.x = 11 }
largering.rotation.x = 0
scene.add(largering)

smallRing.add(smallring.position, 'y').min(- 20).max(20).step(0.01).name('Small Circle Y')
smallRing.add(smallring.position, 'x').min(- 20).max(20).step(0.01).name('Small Circle X')
smallRing.add(smallring.position, 'z').min(- 20).max(20).step(0.01).name('Small Circle Z')

medRing.add(mediumring.position, 'y').min(- 20).max(20).step(0.01).name('Medium Circle Y')
medRing.add(mediumring.position, 'x').min(- 20).max(20).step(0.01).name('Medium Circle X')
medRing.add(mediumring.position, 'z').min(- 20).max(20).step(0.01).name('Medium Circle Z')

bigRing.add(largering.position, 'y').min(- 20).max(20).step(0.01).name('Big Circle Y')
bigRing.add(largering.position, 'x').min(- 20).max(20).step(0.01).name('Big Circle X')
bigRing.add(largering.position, 'z').min(- 20).max(20).step(0.01).name('Big Circle Z')


/**
 * Particles
 */
// Geometry
const particlesCount = 2000
const positions = new Float32Array(particlesCount * 3)
const colors = new Float32Array(particlesCount * 3)
let j = 0

for(let i = 0; i < particlesCount; i++)
{
    //x
    positions[i * 3 + 0] = (Math.random() - 0.5) * 10 * 15
    //y
    positions[i * 3 + 1] = (Math.random() - 0.5) * 10 * 15
    //z
    positions[i * 3 + 2] = (Math.random() - 0.5) * 10 * 15

    //colors[i] = Math.random()



    //RGB TO 0-1 scale just divide by 255
    //switch to choose colors
    switch (j) {
        case 0:
            //blue
            colors[i * 3 + 0] = 0.32
            colors[i * 3 + 1] = 0.88
            colors[i * 3 + 2] = 0.93
            j++
            break;
        case 1:
            //yellow
            colors[i * 3 + 0] = 0.93
            colors[i * 3 + 1] = 0.72
            colors[i * 3 + 2] = 0.35
            j++
            break;
        case 2:
            //brown
            colors[i * 3 + 0] = 0.80
            colors[i * 3 + 1] = 0.35
            colors[i * 3 + 2] = 0.15
            j = 0
            break;
        default:
            j = 0
    }

    
   

    

     


}
const particlesGeometry = new THREE.BufferGeometry()
particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
particlesGeometry.setAttribute('color', new THREE.BufferAttribute(colors, 3))


// Material
const particlesMaterial = new THREE.PointsMaterial({
    color: parameters.materialColor,
    sizeAttenuation: true,
    size: 0.6, 
    map: particleTexture,
    transparent: true, 
    alphaMap: particleTexture, 
    //depthTest: true, 
    depthWrite: false, 
    blending: THREE.AdditiveBlending, 
    vertexColors: true,
})

// Points
const particles = new THREE.Points(particlesGeometry, particlesMaterial)
scene.add(particles)





window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})



/**
 * Cursor
 */
 const cursor = {}
 cursor.x = 0
 cursor.y = 0 

window.addEventListener('mousemove', (event) =>
{
   cursor.x = event.clientX / sizes.width - 0.5
   cursor.y = event.clientY / sizes.height - 0.5

   //console.log(cursor)
})


/**
 * Camera
 */
// Base camera

const cameraGroup = new THREE.Group()
scene.add(cameraGroup)
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.z = 22
cameraGroup.add(camera)

/**
 * Lights
 */
//  const directionalLight = new THREE.DirectionalLight('#ffff00', 1)
//  directionalLight.position.set(20, 20, 0)
//  scene.add(directionalLight)
//  const helper = new THREE.DirectionalLightHelper( directionalLight, 5 );
//  scene.add( helper );

// Controls
// const controls = new OrbitControls(camera, canvas)
// controls.target.set(0, 1, 0)
// controls.enableDamping = true
// controls.enableZoom =  false
/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true, 
    alpha: true, 
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime
    // smallring
    smallMaterial.uniforms.uTime.value = elapsedTime

    // mediuring
    mediumMaterial.uniforms.uTime.value = elapsedTime

    // Largring
     largeMaterial.uniforms.uTime.value = elapsedTime

     // Animate camera
     //camera.position.y = - scrollY / sizes.height * objectsDistance

     const parallaxX = cursor.x * 0.5
     const parallaxY = - cursor.y * 0.5
 
     //Parallax change value here to make smaller
     cameraGroup.position.x += (parallaxX - cameraGroup.position.x) * 5 * deltaTime
     cameraGroup.position.y += (parallaxY - cameraGroup.position.y) * 5 * deltaTime

    smallring.rotation.y += 0.005;
    smallring.rotation.x += 0.005;

    mediumring.rotation.y +=  0.005;
    mediumring.rotation.x +=  0.005;

    largering.rotation.y += 0.005;
    largering.rotation.x += 0.005;


    // make particles Move
    for ( let i = 0; i < scene.children.length; i ++ ) {

        const object = scene.children[ i ];

        if ( object instanceof THREE.Points ) {

            object.rotation.y = - elapsedTime * ( i < 4 ? i + 1 : - ( i + 1 ) ) * 0.005;
            object.rotation.x = elapsedTime * ( i < 4 ? i + 1 : - ( i + 1 ) ) * 0.005;
            object.rotation.z = elapsedTime * ( i < 4 ? i + 1 : - ( i + 1 ) ) * 0.005;
        }

    }


    // Update controls
    //controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()